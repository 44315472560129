import type {
    TUpdateQuestionDraft,
    TCreateQuestionDraft,
    TFetchQuestionDraftSerials,
    TFetchQuestionDraft,
    TConvertPQToPQDraft,
    TConvertPQDraftToQDraft,
    TFetchQuestionDraftExamIds,
    TFetchQuestionDrafts,
    TDeleteQuestionDraft,
    TCheckQuestionPlagiarism,
} from './types'
import { Parse, sessionToken, objPointer, applySearchParams } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import questionDraftsModule from '@/store/questionDrafts/module'
import * as Sentry from '@sentry/browser'
import questionScenarioDraftsModule from '@/store/questionScenarioDrafts/module'

/**
 * Fetch all question draft serials
 */
const fetchQuestionDraftSerials = async (): ReturnType<TFetchQuestionDraftSerials> => {
    const parseSerials = await new Parse.Query('QuestionDraft').select('serial').findAll({ batchSize: 10000 })

    return parseSerials.map(ps => ps.get('serial'))
}

/**
 * Convert IParseQuestionDraft to IQuestionDraft
 *
 * @param {IParseQuestionDraft} question to convert
 */
const convertPQDraftToQDraft = (
    question: Parameters<TConvertPQDraftToQDraft>[0]): ReturnType<TConvertPQDraftToQDraft> => {
    const {
        prompt,
        answers,
        distractors,
        knowledgeAreaDraft,
        references,
        explanation,
        type,
        jobStatus,
        draftStatus,
        job,
        examDraft,
        examDataId,
        passage,
        isArchived = false,
        isFlagged = false,
        serial,
        createdAt,
        updatedAt,
        objectId,
        images,
        isSpecial,
        hasSuggestions,
        hasComments,
        answeredCorrectlyCount,
        answeredIncorrectlyCount,
        choiceStats,
        percentCorrect,
        isMockQuestion,
        bloomTaxonomyLevel,
        subtopicId,
        lastUpdatedBy,
        questionScenarioDraft,
    } = question

    return {
        prompt,
        answers,
        distractors,
        knowledgeAreaDraftId: knowledgeAreaDraft && knowledgeAreaDraft.objectId,
        references,
        explanation,
        type,
        jobStatus,
        draftStatus,
        jobId: job && job.objectId,
        examDraftId: examDraft && examDraft.objectId,
        examDataId,
        passage,
        isArchived,
        serial,
        createdAt,
        updatedAt,
        objectId,
        isFlagged,
        isSpecial,
        isClassic: false,
        images,
        hasSuggestions,
        hasComments,
        answeredCorrectlyCount,
        answeredIncorrectlyCount,
        choiceStats,
        percentCorrect,
        isMockQuestion,
        bloomTaxonomyLevel,
        subtopicId,
        lastUpdatedById: lastUpdatedBy && lastUpdatedBy.objectId,
        questionScenarioDraftId: questionScenarioDraft && questionScenarioDraft.objectId,
    }
}

/**
 * Convert IParseQuestion to IQuestionDraft
 *
 * @param {IParseQuestion} question to convert
 * @param {IExamDraft} examDraft to convert
 * @param {IKnowledgeAreaDraft} knowledgeAreaDraft to convert
 * @param {IUser} lastUpdatedBy to convert
 */
const convertPQToPQDraft = (
    {
        question,
        examDraft,
        knowledgeAreaDraft,
        questionScenarioDraft,
        lastUpdatedBy,
    }: Parameters<TConvertPQToPQDraft>[0]
): ReturnType<TConvertPQToPQDraft> => {
    return {
        prompt: question.prompt,
        distractors: question.choices
            .filter(c => !c.isCorrect)
            .map(c => c.text)
            .filter((choiceText): choiceText is string => !!choiceText),
        answers: question.choices.filter(c => c.isCorrect)
            .map(c => c.text)
            .filter((choiceText): choiceText is string => !!choiceText),
        references: question.references,
        explanation: question.explanation,
        passage: question.passage,
        type: question.type,
        isArchived: question.isArchived,
        examDataId: question.objectId,
        serial: question.serial,
        dateAdded: (question.addedDate as Date).toISOString(),
        draftStatus: 'inactive',
        isFlagged: false,
        isSpecial: question.isFree,
        isClassic: false,
        hasComments: false,
        hasSuggestions: false,
        images: (question.explanationImage || question.passageImage)
            ? {
                explanation: question.explanationImage,
                passage: question.passageImage,
            }
            : undefined,
        examDraft:
            (
                examDraft &&
                examDraft.objectId &&
                objPointer(examDraft.objectId)('ExamDraft')
            )
            || undefined,
        knowledgeAreaDraft:
            (
                knowledgeAreaDraft &&
                knowledgeAreaDraft.objectId &&
                objPointer(knowledgeAreaDraft.objectId)('KnowledgeAreaDraft')
            )
            || undefined,
        questionScenarioDraft:
            (
                questionScenarioDraft &&
                questionScenarioDraft.objectId &&
                objPointer(questionScenarioDraft.objectId)('QuestionScenarioDraft')
            ) || undefined,
        lastUpdatedBy: 
            (
                lastUpdatedBy &&
                lastUpdatedBy.objectId &&
                objPointer(lastUpdatedBy.objectId)('_User')
            )
            || undefined,
        job: undefined,
        jobStatus: undefined,
        // The following are present on CMS.Cloud.ExamDataWithMetrics
        answeredCorrectlyCount: question.answeredCorrectlyCount,
        answeredIncorrectlyCount: question.answeredIncorrectlyCount,
        choiceStats: question.choiceStats,
        percentCorrect: question.percentCorrect,
        isMockQuestion: question.isMockQuestion,
        appName: examDraft?.appName,
        bloomTaxonomyLevel: question.bloomTaxonomyLevel,
        subtopicId: question.subtopicId,
        matrixChoiceLayout: question.matrixChoiceLayout,
        matrixLabels: question.matrixLabels,
        passageLabel: question.passageLabel,
    }
}

/**
 * Fetch all question ExamDataIDs so we can keep track active questions
 *
 * @returns {Promise} resolves with string[] of ExamDataIds when query completes
 */
const fetchQuestionDraftExamIds = async (): ReturnType<TFetchQuestionDraftExamIds> => {
    const questionQuery = new Parse.Query<Parse.Object>('QuestionDraft')

    const questionResults = (await questionQuery.select('examDataId', 'jobStatus', 'job').findAll({
        ...sessionToken(),
        batchSize: 2000,
    }))

    // pull examDataId's off of results and commit to store
    const questionIds: string[] = questionResults.map(question => question.get('examDataId'))
    questionDraftsModule.state.questionDraftExamIds = questionIds

    return questionIds
}

/**
 * Fetch questions based on search parameters
 *
 * @param {int} [perPage=20] - number of questions to return per page
 * @param {int} [page=0] - current page starting at page 0
 * @param {string} [searchAll] - string for searching all columns with an OR query
 * @param {string} [orderBy] - column to order by
 * @param {string} [order='ascending'] - which way to order ("ascending" / "descending")
 * @param {object} [equalTo] - key/value pairs to match exactly with "equalTo"
 * @param {object} [notEqualTo] - key/value pairs to match exactly with "notEqualTo"
 * @param {object} [containedIn] - key/value pairs to match with "containedIn"
 * @param {object} [contains] - key/value pairs to match with "contains"
 * @param {object} [containsAll] - key/value pairs to match with "containsAll"
 *
 * @returns { Promise<IParseQuestionDraft[]> } resolves with array of toJSON question drafts
 */
const fetchQuestionDrafts = async ({
    searchAll,
    ...searchParams
}: Parameters<TFetchQuestionDrafts>[0] = {
    perPage: 20,
    page: 0,
}): ReturnType<TFetchQuestionDrafts> => {
    let searchQuery: Parse.Query<CMS.Class.QuestionDraft>

    // If "searchAll" is specified, create an OR query. Otherwise, create a single query.
    if (searchAll && searchAll.value) {
        const searchAllQueries = searchAll.keys.map(key => {
            const query = new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
            query.matches(key, new RegExp(searchAll.value), searchParams.caseSensitive ? undefined : 'i')
            return query
        })
        searchQuery = Parse.Query.or(...searchAllQueries)
    } else {
        searchQuery = new Parse.Query('QuestionDraft')
    }

    // If "orderBy" is specified without "perPage", add a default limit of 10000
    // findAll() does not work with query sorting, so we have to use find() with a large enough limit instead
    if (searchParams.orderBy && !searchParams.perPage) {
        searchParams.perPage = 10000
    }
    // Applying the search params adds pagination, filtering, sorting, etc and returns the total result count
    const totalCount = await applySearchParams(searchQuery, searchParams)
    const questions = searchParams.perPage
        ? await searchQuery.find()
        : await searchQuery.findAll({ batchSize: 10000 })
    const questionsMapped = questions
        .map(question => question.toJSON())
        // ensure examDraft and job are pointers (not full objects)
        // this is necessary if we ever want to use this object to update
        // the question again in Parse
        .map(question => ({
            ...question,
            questionScenarioDraft: question.questionScenarioDraft && {
                __type: 'Pointer',
                className: 'QuestionScenarioDraft',
                objectId: question.questionScenarioDraft.objectId || '',
            },
            examDraft: question.examDraft && {
                __type: 'Pointer',
                className: 'ExamDraft',
                objectId: question.examDraft.objectId || '',
            },
            job: question.job && {
                __type: 'Pointer',
                className: 'Job',
                objectId: question.job.objectId || '',
            },
            knowledgeAreaDraft: question.knowledgeAreaDraft && {
                __type: 'Pointer',
                className: 'KnowledgeAreaDraft',
                objectId: question.knowledgeAreaDraft.objectId || '',
            },
        }))
    return {
        results: questionsMapped,
        totalCount,
    }
}

/**
 * Fetch single question by id
 *
 * @param {string} questionDraftId - ID of question draft to fetch
 *
 * @returns {Promise} resolves to Parse.Object of fetched question draft object or undefined if no result found
 */
const fetchQuestionDraft = async (
    questionDraftId: Parameters<TFetchQuestionDraft>[0]): ReturnType<TFetchQuestionDraft> => {
    return (await new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
        .get(questionDraftId)).toJSON()
}

/**
 * Create new question draft
 *
 * @param {string} prompt - question prompt text
 * @param {string[]} [answers=[]] - correct answers array
 * @param {string[]} [distractors=[]] - incorrect answers array
 * @param {string} knowledgeAreaDraft - name of knowledge area
 * @param {string[]} [references=[]] - references for question
 * @param {string} [explanation] - explanation for question
 * @param {string} [passage] - passage for question
 * @param {string} type - type of question
 * @param {string} [jobStatus='Writer'] - current jobStatus of question
 * @param {string} jobId - ID of job to associate with question
 *
 * @returns {Promise} resolves to Parse.Object of new question
 */
const createQuestionDraft = async (
    {
        prompt,
        answers = [],
        distractors = [],
        knowledgeAreaDraftId,
        references = [],
        explanation = '',
        passage = '',
        type,
        jobStatus = 'Writer',
        jobId,
        examDraftId,
        appName,
        isArchived,
        isSpecial,
        images,
        examDataId,
        hasComments,
        hasSuggestions,
        serial,
        dateAdded,
        subCategory,
        draftStatus = 'active',
        answeredCorrectlyCount,
        answeredIncorrectlyCount,
        choiceStats,
        percentCorrect,
        isMockQuestion,
        bloomTaxonomyLevel,
        subtopicId,
        lastUpdatedById,
        questionScenarioDraftId,
        matrixLabels,
        matrixChoiceLayout,
        passageLabel,
    }: Parameters<TCreateQuestionDraft>[0]): ReturnType<TCreateQuestionDraft> => {
    // throw if no exam draft id is passed
    if (!examDraftId) {
        throw 'Unable to create question draft.'
    }

    const newQuestion = new Parse.Object('QuestionDraft', {
        prompt,
        answers,
        distractors,
        knowledgeAreaDraft: knowledgeAreaDraftId
            ? objPointer(knowledgeAreaDraftId)('KnowledgeAreaDraft')
            : undefined,
        references,
        explanation,
        passage,
        type,
        jobStatus,
        job: jobId
            ? objPointer(jobId)('Job')
            : undefined,
        examDraft: objPointer(examDraftId)('ExamDraft'),
        appName,
        isArchived,
        isClassic: false,
        isSpecial,
        isFlagged: false,
        images,
        draftStatus,
        examDataId,
        hasComments,
        hasSuggestions,
        serial,
        dateAdded,
        subCategory,
        answeredCorrectlyCount: answeredCorrectlyCount || 0,
        answeredIncorrectlyCount: answeredIncorrectlyCount || 0,
        choiceStats: choiceStats || {},
        percentCorrect: percentCorrect || 0,
        isMockQuestion,
        bloomTaxonomyLevel,
        subtopicId,
        lastUpdatedBy: lastUpdatedById
            ? objPointer(lastUpdatedById)('_User')
            : undefined,
        questionScenarioDraft: questionScenarioDraftId
            ? objPointer(questionScenarioDraftId)('QuestionScenarioDraft')
            : undefined,
        matrixLabels,
        matrixChoiceLayout,
        passageLabel,
    })

    await newQuestion.save(null, sessionToken())

    // if no serial passed, save the object ID as the new serial
    if (!serial) {
        // Capture if we somehow have no serial or id - see CMS-387
        if (!newQuestion.id) {
            Sentry.captureException(new Error(`createQuestionDraft: Missing serial and id. Prompt: ${prompt}`))
        } else {
            newQuestion.set('serial', newQuestion.id)
            await newQuestion.save(null, sessionToken())
        }
    }

    return newQuestion.toJSON()
}

/**
 * Update question and commit updated question to store
 *
 * @param {string} questionId - ID of question to update
 * @param {object} params - Key/value pairs to update Parse object
 *
 * @returns {Promise} resolves to updated question's params or
 * undefined if question id does not match an question in the store
 */
const updateQuestionDraft = async (
    { questionId, params, options }: Parameters<TUpdateQuestionDraft>[0]): ReturnType<TUpdateQuestionDraft> => {
    // throw if no exam draft id is passed
    if (!params.examDraftId) {
        throw 'Unable to update question draft.'
    }

    const question = new Parse.Object('QuestionDraft',
        {
            objectId: questionId,
            prompt: params.prompt,
            answers: params.answers,
            distractors: params.distractors,
            knowledgeAreaDraft: params.knowledgeAreaDraftId
                ? objPointer(params.knowledgeAreaDraftId)('KnowledgeAreaDraft')
                : undefined,
            references: params.references,
            explanation: params.explanation,
            passage: params.passage,
            type: params.type,
            job: params.jobId
                ? objPointer(params.jobId)('Job')
                : undefined,
            examDraft: objPointer(params.examDraftId)('ExamDraft'),
            appName: params.appName,
            isFlagged: params.isFlagged,
            jobStatus: params.jobStatus,
            draftStatus: params.draftStatus,
            isArchived: params.isArchived,
            images: params.images,
            isClassic: false,
            isSpecial: params.isSpecial,
            hasComments: params.hasComments,
            hasSuggestions: params.hasSuggestions,
            answeredCorrectlyCount: params.answeredCorrectlyCount,
            answeredIncorrectlyCount: params.answeredIncorrectlyCount,
            choiceStats: params.choiceStats,
            percentCorrect: params.percentCorrect,
            isMockQuestion: params.isMockQuestion,
            bloomTaxonomyLevel: params.bloomTaxonomyLevel,
            subtopicId: params.subtopicId,
            lastUpdatedBy: params.lastUpdatedById
                ? objPointer(params.lastUpdatedById)('_User')
                : undefined,
            questionScenarioDraft: params.questionScenarioDraftId
                ? objPointer(params.questionScenarioDraftId)('QuestionScenarioDraft')
                : undefined,
            matrixLabels: params.matrixLabels,
            matrixChoiceLayout: params.matrixChoiceLayout,
            passageLabel: params.passageLabel,
        } as CMS.Class.QuestionDraftPayload) as CMS.Class.QuestionDraft

    if (!params.passage) {
        question.unset('passage')
    }

    // if no serial passed, save the object ID as the new serial
    if (!params.serial) {
        question.set('serial', questionId)
    }

    await question.save(null, sessionToken())

    if (params.questionScenarioDraftId) {
        await questionScenarioDraftsModule.actions.syncScenarioFromQuestion({
            sourceQuestionDraftId: questionId || params.objectId,
            questionScenarioDraftId: params.questionScenarioDraftId,
            passageLabel: params.passageLabel,
            sharedPassage: params.passage,
            isUnfoldingScenario: options?.isUnfoldingScenario || false,
        })
    }

    return question.toJSON()
}

const updateScenarioDraftOnQuestionDraft = async (params: {
    questionDraftId: string
    newScenarioDraftId?: string
    oldScenarioDraftId?: string
}) => {
    const questionDraft = new Parse.Object<Partial<CMS.Class.QuestionDraftPayload>>('QuestionDraft', {
        objectId: params.questionDraftId,
    })
    const questionDraftSerial = questionDraft.get('serial')
    if (!questionDraftSerial) {
        throw new Error('Cannot update scenario drafts for question draft without serial')
    }

    const scenarioDraftIds = [ params.newScenarioDraftId, params.oldScenarioDraftId ].filter((id): id is string => !!id)
    const questionScenarioDrafts = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
        .containedIn('objectId', scenarioDraftIds)
        .find(sessionToken())
    const { newScenarioDraft, oldScenarioDraft } = Object.fromEntries(questionScenarioDrafts.map(scenarioDraft => {
        return [
            scenarioDraft.id === params.newScenarioDraftId ? 'newScenarioDraft' : 'oldScenarioDraft',
            scenarioDraft,
        ]
    }))

    // If new scenario exists, fetch one of its questions to copy the shared passage label
    const newScenarioFirstSerial = newScenarioDraft?.get('questionDrafts')[0]?.serial
    const newScenarioFirstQuestion = newScenarioFirstSerial
        && await new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
            .equalTo('examDraft', newScenarioDraft.get('examDraft'))
            .equalTo('serial', newScenarioFirstSerial)
            .first(sessionToken())

    // Connect the question to the new scenario, or else remove the scenario pointer
    if (newScenarioDraft) {
        if (newScenarioFirstQuestion) { // Sync the passage label if we have one
            questionDraft.set('passageLabel', newScenarioFirstQuestion.get('passageLabel'))
        }
        questionDraft.set('questionScenarioDraft', newScenarioDraft)
        questionDraft.set('passage', newScenarioDraft.get('sharedPassage'))
        if (!newScenarioDraft.get('questionDrafts').find(qd => qd.serial === questionDraftSerial)) {
            newScenarioDraft.addUnique('questionDrafts', { serial: questionDraftSerial })
        }
    } else {
        questionDraft.unset('questionScenarioDraft')
    }

    // If the old scenario is provided, remove the question from its list
    if (oldScenarioDraft) {
        oldScenarioDraft.remove('questionDrafts', { serial: questionDraftSerial })
    }

    await Promise.all([
        questionDraft.save(null, sessionToken()),
        newScenarioDraft?.save(null, sessionToken()),
        oldScenarioDraft?.save(null, sessionToken()),
    ])
    return questionDraft.toJSON() as CMS.Class.QuestionDraftJSON
}

const upsertQuestionDrafts = async (params: {
    questionDrafts: { objectId?: string } & Partial<CMS.Class.QuestionDraftPayload>[]
    generateKeywords?: boolean
}) => {
    const { questionDrafts, generateKeywords } = params

    const mappedQuestionDrafts = (questionDrafts || []).map(
        questionDraft => {
            const newQuestion = new Parse.Object('QuestionDraft',
                {
                    ...questionDraft,
                })
            return newQuestion
        }
    )

    const upsertedQuestions = await Parse.Object.saveAll(mappedQuestionDrafts)

    if (generateKeywords) {
        // Queue the questions up for keyword gen on upsert
        await upsertKeywordGenerationQueueMessages(upsertedQuestions.map(uq => uq.id))
    }

    const questionIds = upsertedQuestions
        .map(question => question.get('examDataId'))
        .filter((questionId): questionId is string => !!(questionId))
    questionDraftsModule.state.questionDraftExamIds = [
        ...questionIds,
        ...questionDraftsModule.state.questionDraftExamIds,
    ]
}

/**
 * Request a plagiarism check on a particular question
 *
 * @param {string} draftId - ID of the question draft to check for plagiarism
 * @returns
 */
const checkQuestionPlagiarism = async (
    draftId: Parameters<TCheckQuestionPlagiarism>[0]
): ReturnType<TCheckQuestionPlagiarism> => {
    return await Parse.Cloud.run('checkQuestionDraftForPlagiarism', { draftId }) && true
}

/**
 * Delete question draft
 *
 * @param {string} questionDraftId - ID of question to delete
 *
 * @returns {Prpmose<boolean>}
 */
const deleteQuestionDraft = async (
    questionDraftId: Parameters<TDeleteQuestionDraft>[0]): ReturnType<TDeleteQuestionDraft> => {
    const question = await new Parse.Query('QuestionDraft')
        .equalTo('objectId', questionDraftId)
        .first()

    if (question) {
        // Delete the question and any keyword gen messages for it
        await Promise.all([
            question.destroy(),
            deleteKeywordGenerationQueueMessages(question.id),
        ])
        return true
    }
    return false
}

const getBloomLevelForQuestion = async (questionDraftId: string) => {
    return Parse.Cloud.run<CMS.Cloud.getBloomLevelForQuestion>('getBloomLevelForQuestion', { questionDraftId })
}

// Fetch question drafts that point to a scenario draft
const fetchQuestionDraftsByQuestionScenarioDraftId = async (questionScenarioDraftId: string) => {
    const questionDrafts = await new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
        .equalTo('questionScenarioDraft', objPointer(questionScenarioDraftId)('QuestionScenarioDraft'))
        .find()
    return questionDrafts.map(qDraft => qDraft.toJSON())
}

// Move to types when stable
type UpsertKeywordGenerationQueueMessage = (params: {
    questionDraftIds: string[]
}) => void

// Add the question draft to the queue for keyword generation
const upsertKeywordGenerationQueueMessages = async (questionDraftIds: string[]) => {
    return Parse.Cloud.run<UpsertKeywordGenerationQueueMessage>('upsertKeywordGenerationQueueMessages', {
        questionDraftIds,
    })
}

// Move to types when stable
type DeleteKeywordGenerationQueueMessages = (params: {
    questionDraftId: string
}) => void

const deleteKeywordGenerationQueueMessages = async (questionDraftId: string) => {
    return Parse.Cloud.run<DeleteKeywordGenerationQueueMessages>('deleteKeywordGenerationQueueMessages', {
        questionDraftId,
    })
}

export default {
    fetchQuestionDraftSerials,
    convertPQDraftToQDraft,
    convertPQToPQDraft,
    fetchQuestionDraftExamIds,
    fetchQuestionDrafts,
    fetchQuestionDraft,
    createQuestionDraft,
    updateQuestionDraft,
    updateScenarioDraftOnQuestionDraft,
    upsertQuestionDrafts,
    checkQuestionPlagiarism,
    deleteQuestionDraft,
    getBloomLevelForQuestion,
    fetchQuestionDraftsByQuestionScenarioDraftId,
    upsertKeywordGenerationQueueMessages,
}
